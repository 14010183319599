/*
 * @Author: jianying.wan
 * @Date: 2019-12-12 10:48:01
 * @LastEditors  : jianying.wan
 * @LastEditTime : 2019-12-25 10:49:44
 * @Description: file content
 */

// 获取优惠券列表
const getCouponListURL = `${API_CONFIG.shopBaseURL}carParkCoupon/list`
// 详情
const getDetailURL = `${API_CONFIG.shopBaseURL}carParkCoupon/info`
// 新增优惠券
const addCouponURL = `${API_CONFIG.shopBaseURL}carParkCoupon/add`
// 编辑优惠券
const editCouponURL = `${API_CONFIG.shopBaseURL}carParkCoupon/edit`
// 停车场select2
const parkingSelect2URL = `${API_CONFIG.shopBaseURL}carParkList/carParkInfoByParam`
// 导出优惠券列表
const exportCouponURL = `${API_CONFIG.shopBaseURL}carParkCoupon/export`
// 发放记录列表
const releaseRecordListURL = `${API_CONFIG.shopBaseURL}carParkCoupon/releaseRecord/list`
// 券池编号select2
const couponPoolSelect2URL = `${API_CONFIG.shopBaseURL}carParkCoupon/couponPoolNumberList`
// 导出发放记录
const exportCouponReleaseURL = `${API_CONFIG.shopBaseURL}carParkCoupon/releaseRecord/export`
// 发放记录统计接口
const releaseRecordStatsURL = `${API_CONFIG.shopBaseURL}carParkCoupon/releaseRecord/count`
// 获取房屋列表
const getRoomListURL = `${API_CONFIG.butlerBaseURL}communityRoom/listCommunityRoom`
// 查询房号
const select2RoomIdUrl = `${API_CONFIG.butlerBaseURL}orgInfo/house/by/communityId`
export {
  getCouponListURL,
  getDetailURL,
  addCouponURL,
  editCouponURL,
  parkingSelect2URL,
  exportCouponURL,
  releaseRecordListURL,
  couponPoolSelect2URL,
  exportCouponReleaseURL,
  releaseRecordStatsURL,
  getRoomListURL,
  select2RoomIdUrl,
}
