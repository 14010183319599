var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ParkingCouponForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _vm.isEdit
                    ? _c("el-form-item", { attrs: { label: "券池编号" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.couponNumber))]),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "优惠券名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入优惠券名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "couponName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { width: _vm.width, maxlength: 10 },
                        model: {
                          value: _vm.form.couponName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "couponName", $$v)
                          },
                          expression: "form.couponName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属项目",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { width: _vm.width, disabled: _vm.isEdit },
                            on: { onChange: _vm.onCommunityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "停车场",
                        rules: [
                          {
                            required: true,
                            message: "请选择停车场",
                            trigger: "blur",
                          },
                        ],
                        prop: "carParkId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            ref: "carPark",
                            attrs: {
                              width: _vm.width,
                              subjoin: _vm.communitySubjoin,
                              disabled:
                                !(
                                  _vm.form.communityId &&
                                  _vm.form.communityId.length
                                ) || _vm.isEdit,
                            },
                            model: {
                              value: _vm.form.carParkId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carParkId", $$v)
                              },
                              expression: "form.carParkId",
                            },
                          },
                          "v-select2",
                          _vm.parkingParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择房号",
                        rules: [
                          {
                            required: !_vm.isEdit,
                            message: "请选择房号",
                            trigger: "change",
                            type: "array",
                          },
                        ],
                        prop: "houseIds",
                      },
                    },
                    [
                      !_vm.isEdit
                        ? _c("chosenListPanel", {
                            attrs: {
                              disabled: !_vm.form.communityId,
                              list: _vm.form.houseIds,
                            },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(_vm.form, "houseIds", $event)
                              },
                              select: _vm.houseMultiSelect,
                            },
                          })
                        : _c("div", [_vm._v(_vm._s(_vm.form.houseNum))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "优惠券类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择优惠券类型",
                            trigger: "blur",
                          },
                        ],
                        prop: "couponType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.typeOps,
                          disabled: _vm.isEdit,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.couponType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "couponType", $$v)
                          },
                          expression: "form.couponType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.needPrice
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单张金额",
                            rules: [
                              {
                                required: true,
                                message: "请输入单张金额",
                                trigger: "blur",
                              },
                            ],
                            prop: "price",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: { min: 1, max: 999 },
                            model: {
                              value: _vm.form.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "price", $$v)
                              },
                              expression: "form.price",
                            },
                          }),
                          _c("span", { staticClass: "unit" }, [_vm._v("元")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "总张数",
                        rules: [
                          {
                            required: true,
                            message: "请输入总张数",
                            trigger: "blur",
                          },
                        ],
                        prop: "totalQuantity",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          controls: true,
                          min: 1,
                          max: 99999,
                          disabled: _vm.isEdit,
                        },
                        model: {
                          value: _vm.form.totalQuantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "totalQuantity", $$v)
                          },
                          expression: "form.totalQuantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "财务备注" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "可填写“线下押金”或“预充值”金额。",
                          maxlength: 200,
                        },
                        model: {
                          value: _vm.form.rechargeRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "rechargeRemark", $$v)
                          },
                          expression: "form.rechargeRemark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "使用有效期",
                        rules: [
                          {
                            required: true,
                            message: "请选择使用有效期",
                            trigger: "blur",
                          },
                        ],
                        prop: "expirationDateType",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("checkbox-plus", {
                            ref: "checkbox",
                            staticClass: "radio-box",
                            attrs: {
                              type: "radio",
                              options: _vm.expirationDateOps,
                              disabled: _vm.isEdit,
                              value: _vm.form.expirationDateType,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "expirationDateType",
                                  $event
                                )
                              },
                            },
                          }),
                          _vm.form.expirationDateType === "1"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入使用有效期",
                                            trigger: "blur",
                                          },
                                        ],
                                        prop: "expirationHour",
                                      },
                                    },
                                    [
                                      _c("v-input-number", {
                                        attrs: {
                                          min: 1,
                                          max: 30,
                                          controls: true,
                                          disabled: _vm.isEdit,
                                        },
                                        model: {
                                          value: _vm.form.expirationHour,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "expirationHour",
                                              $$v
                                            )
                                          },
                                          expression: "form.expirationHour",
                                        },
                                      }),
                                      _c("span", { staticClass: "unit" }, [
                                        _vm._v(
                                          "天有效（最长30天，不包含当天）"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发放有效期",
                        rules: _vm.timeRules,
                        prop: "time",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "rangedate",
                          startTime: _vm.form.time.startTime,
                          endTime: _vm.form.time.endTime,
                          "min-date": new Date(),
                        },
                        on: {
                          "update:startTime": function ($event) {
                            return _vm.$set(_vm.form.time, "startTime", $event)
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(_vm.form.time, "startTime", $event)
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(_vm.form.time, "endTime", $event)
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(_vm.form.time, "endTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.couponType !== 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "过期未使用",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "blur",
                              },
                            ],
                            prop: "expiredRecycling",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.returnTypeOps,
                              disabled: _vm.isEdit,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.expiredRecycling,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "expiredRecycling", $$v)
                              },
                              expression: "form.expiredRecycling",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showCouponStatus
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "blur",
                              },
                            ],
                            prop: "couponStatus",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.formEditStatusOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.couponStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "couponStatus", $$v)
                              },
                              expression: "form.couponStatus",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "multi-select",
        _vm._b(
          {
            attrs: {
              title: "房号列表",
              isShow: _vm.houseMultiShow,
              searchParams: _vm.houseSearchParams,
              backFill: _vm.form.houseIds,
              extraParams: _vm.houseExtraParams,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.houseMultiShow = $event
              },
              "update:is-show": function ($event) {
                _vm.houseMultiShow = $event
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "houseIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "houseIds", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-select", {
                      attrs: { label: "房屋类型", options: _vm.houseTypeOps },
                      model: {
                        value: _vm.houseSearchParams.houseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.houseSearchParams, "houseType", $$v)
                        },
                        expression: "houseSearchParams.houseType",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "房屋状态", options: _vm.roomStatusOps },
                      model: {
                        value: _vm.houseSearchParams.roomStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.houseSearchParams, "roomStatus", $$v)
                        },
                        expression: "houseSearchParams.roomStatus",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "苑" },
                      model: {
                        value: _vm.houseSearchParams.blockName,
                        callback: function ($$v) {
                          _vm.$set(_vm.houseSearchParams, "blockName", $$v)
                        },
                        expression: "houseSearchParams.blockName",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "幢" },
                      model: {
                        value: _vm.houseSearchParams.buildingNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.houseSearchParams, "buildingNumber", $$v)
                        },
                        expression: "houseSearchParams.buildingNumber",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "单元" },
                      model: {
                        value: _vm.houseSearchParams.unit,
                        callback: function ($$v) {
                          _vm.$set(_vm.houseSearchParams, "unit", $$v)
                        },
                        expression: "houseSearchParams.unit",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "室" },
                      model: {
                        value: _vm.houseSearchParams.room,
                        callback: function ($$v) {
                          _vm.$set(_vm.houseSearchParams, "room", $$v)
                        },
                        expression: "houseSearchParams.room",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multi-select",
          _vm.houseMultiParams,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }