<!--
 * @Author: jianying.wan
 * @Date: 2019-12-12 15:18:41
 * @LastEditors  : jianying.wan
 * @LastEditTime : 2019-12-30 18:23:49
 * @Description: 停车优惠券表单
 -->
<template>
  <div class="ParkingCouponForm">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                @update="update">
      <col2-detail>
        <col2-block>
          <el-form-item label="券池编号"
                        v-if="isEdit">
            <span>{{ form.couponNumber }}</span>
          </el-form-item>
          <el-form-item label="优惠券名称"
                        :rules="[{ required: true, message: '请输入优惠券名称', trigger: 'blur' }]"
                        prop="couponName">
            <v-input :width="width"
                     :maxlength="10"
                     v-model="form.couponName"></v-input>
          </el-form-item>
          <el-form-item label="所属项目"
                        :rules="[{ required: true, message: '请选择所属项目', trigger: 'blur' }]"
                        prop="communityId">
            <v-select2 v-model="form.communityId"
                       v-bind="communityParams"
                       :width="width"
                       :disabled="isEdit"
                       @onChange="onCommunityChange"/>
          </el-form-item>
          <el-form-item label="停车场"
                        :rules="[{ required: true, message: '请选择停车场', trigger: 'blur' }]"
                        prop="carParkId">
            <v-select2 ref="carPark"
                       v-model="form.carParkId"
                       v-bind="parkingParams"
                       :width="width"
                       :subjoin="communitySubjoin"
                       :disabled="!(form.communityId && form.communityId.length) || isEdit" />
          </el-form-item>
          <el-form-item label="选择房号" :rules="[{ required: !isEdit, message: '请选择房号', trigger: 'change', type: 'array' }]" prop="houseIds">
             <chosenListPanel v-if="!isEdit" :disabled="!form.communityId"  :list.sync="form.houseIds" @select="houseMultiSelect"></chosenListPanel>
              <div v-else>{{form.houseNum}}</div>
          </el-form-item>
          <!--<el-form-item label="所属组织"-->
          <!--              :rules="[{ required: true, message: '请选择所属组织', trigger: 'blur' }]"-->
          <!--              prop="orgId">-->
          <!--  <v-select2 ref="org"-->
          <!--             v-model="form.orgId"-->
          <!--             v-bind="orgParams"-->
          <!--             :width="width"-->
          <!--             :subjoin="communitySubjoin"-->
          <!--             :disabled="!(form.communityId && form.communityId.length) || isEdit" />-->
          <!--</el-form-item>-->
          <el-form-item label="优惠券类型"
                        :rules="[{ required: true, message: '请选择优惠券类型', trigger: 'blur' }]"
                        prop="couponType">
            <v-select v-model="form.couponType"
                      :options="typeOps"
                      :disabled="isEdit"
                      :width="width"></v-select>
          </el-form-item>
          <el-form-item label="单张金额"
                        v-if="needPrice"
                        :rules="[{ required: true, message: '请输入单张金额', trigger: 'blur' }]"
                        prop="price">
            <v-input-number :min="1"
                            :max="999"
                            v-model="form.price"></v-input-number>
            <span class="unit">元</span>
          </el-form-item>
          <el-form-item label="总张数"
                        :rules="[{ required: true, message: '请输入总张数', trigger: 'blur' }]"
                        prop="totalQuantity">
            <v-input-number :controls="true"
                            :min="1"
                            :max="99999"
                            :disabled="isEdit"
                            v-model="form.totalQuantity"></v-input-number>
          </el-form-item>
          <!-- <el-form-item label="充值金额">
            <div>
              <v-input-number :precision="2"
                              :min="0.01"
                              :max="99999999.99"
                              v-model="form.rechargeAmount"></v-input-number>
              <span class="unit">元</span>
              <span>实付金额：</span>
              <v-input-number :precision="2"
                              :min="0.01"
                              :max="99999999.99"
                              v-model="form.actuallyPaidAmount"></v-input-number>
              <span class="unit">元</span>
            </div>
          </el-form-item> -->
          <el-form-item label="财务备注">
            <v-textarea v-model="form.rechargeRemark"
                        placeholder="可填写“线下押金”或“预充值”金额。"
                        :maxlength="200" />
          </el-form-item>
          <el-form-item label="使用有效期"
                        :rules="[{ required: true, message: '请选择使用有效期', trigger: 'blur' }]"
                        prop="expirationDateType">
            <div style="display: flex;">
              <checkbox-plus ref="checkbox"
                             class="radio-box"
                             type="radio"
                             :options="expirationDateOps"
                             :disabled="isEdit"
                             :value.sync="form.expirationDateType" />
              <div v-if="form.expirationDateType === '1'">
                <el-form-item :rules="[{ required: true, message: '请输入使用有效期', trigger: 'blur' }]"
                              prop="expirationHour">
                  <v-input-number :min="1"
                                  :max="30"
                                  :controls="true"
                                  :disabled="isEdit"
                                  v-model="form.expirationHour"></v-input-number>
                  <span class="unit">天有效（最长30天，不包含当天）</span>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="发放有效期"
                        :rules="timeRules"
                        prop="time">
            <v-datepicker type="rangedate"
                          :startTime.sync="form.time.startTime"
                          :endTime.sync="form.time.endTime"
                          :min-date="new Date()" />
          </el-form-item>
          <el-form-item label="过期未使用"
                        :rules="[{ required: true, message: '当前选项不允许为空', trigger: 'blur' }]"
                        prop="expiredRecycling"
                        v-if="form.couponType !== 2">
            <v-select v-model="form.expiredRecycling"
                      :options="returnTypeOps"
                      :disabled="isEdit"
                      :width="width"></v-select>
          </el-form-item>
          <el-form-item label="状态"
                        :rules="[{ required: true, message: '当前选项不允许为空', trigger: 'blur' }]"
                        prop="couponStatus"
                        v-if="showCouponStatus">
            <v-select v-model="form.couponStatus"
                      :options="formEditStatusOps"
                      :width="width"></v-select>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="房号列表"
      :isShow.sync="houseMultiShow"
      :searchParams="houseSearchParams"
      v-bind="houseMultiParams"
      :backFill.sync="form.houseIds"
      :extraParams="houseExtraParams">
      <template #searchSlot>
          <v-select label="房屋类型" v-model="houseSearchParams.houseType" :options="houseTypeOps"></v-select>
          <v-select label="房屋状态" v-model="houseSearchParams.roomStatus" :options="roomStatusOps"></v-select>
          <v-input label="苑" v-model="houseSearchParams.blockName"></v-input>
          <v-input label="幢" v-model="houseSearchParams.buildingNumber"></v-input>
          <v-input label="单元" v-model="houseSearchParams.unit"></v-input>
          <v-input label="室" v-model="houseSearchParams.room"></v-input>
      </template>
    </multi-select>
  </div>
</template>

<script>
import { getDetailURL, addCouponURL, editCouponURL, parkingSelect2URL, getRoomListURL } from './api'
import { Col2Detail, Col2Block, CheckboxPlus,  MultiSelect,
    ChosenListPanel } from 'components/bussiness'
import { orgParams, communityParams } from 'common/select2Params'
import { setStatusOps,
  setTypeOps, returnTypeOps, formEditStatusOps, houseTypeMap, roomStatusMap, roomStatusOps } from './map'
import moment from 'moment'
import {getHouseTypeData} from "common/networkData";

export default {
  name: 'ParkingCouponForm',
  components: {
    Col2Detail,
    Col2Block,
    CheckboxPlus,
    MultiSelect,
    ChosenListPanel
  },
  data () {
    return {
      width: 200,
      communityParams,
      orgParams, 
      roomStatusOps: roomStatusOps(),
      parkingParams: {
        searchUrl: parkingSelect2URL,
        request: {
          text: 'carParkName',
          value: 'carParkId'
        },
        response: {
          text: 'parkingName'
        }
      },
      statusOps: setStatusOps(2),
      typeOps: setTypeOps(2),
      returnTypeOps,
      formEditStatusOps,
      expirationDateOps: [
        {
          label: '当天有效',
          value: '0'
        },
        {
          label: '发放后',
          value: '1'
        }
      ],
      form: {
        actuallyPaidAmount: undefined,
        carParkId: '',
        communityId: '',
        couponDuration: undefined,
        couponName: '',
        couponNumber: '',
        couponStatus: undefined,
        couponType: undefined,
        expirationDateType: '0',
        expirationHour: undefined,
        expiredRecycling: 0,
        price: undefined,
        rechargeAmount: undefined,
        rechargeRemark: '',
        totalQuantity: undefined,
        houseIds: [],
        time: {
          startTime: moment().format('YYYY-MM-DD'),
          endTime: moment().add(30, 'days').format('YYYY-MM-DD')
        }
      },
      timeRules: [
        {
          validator: (rule, value, callback) => {
            if (value) {
              let { startTime, endTime } = value
              if (!startTime) {
                callback(new Error(`请选择开始时间`))
              } else if (!endTime) {
                callback(new Error(`请选择结束时间`))
              } else {
                callback()
              }
            } else {
              callback(new Error(`请选择发放有效期`))
            }
          },
          trigger: 'blur'
        },
        {
          required: true
        }
      ],
      submitConfig: {
        queryUrl: getDetailURL,
        submitUrl: ''
      },
        houseMultiShow: false,
        houseSearchParams: {
            communityId: '',
            houseType: undefined,
            roomStatus: undefined,
            blockName: '',
            buildingNumber: '',
            unit: '',
            room: '',
            orgId: ''
        },
        houseMultiParams: {
            searchUrl: getRoomListURL,
            headers: [
                {
                    prop: 'houseTypeText',
                    label: '房屋类型',
                    formatter (row) {
                        return houseTypeMap[row.houseType]
                    }
                },
                {
                    prop: 'userNameText',
                    label: '房屋状态',
                    formatter (row) {
                        return roomStatusMap[row.roomStatus]
                    }
                },
                {
                    prop: 'blockName',
                    label: '苑'
                },
                {
                    prop: 'buildingNumber',
                    label: '幢'
                },
                {
                    prop: 'unit',
                    label: '单元'
                },
                {
                    prop: 'room',
                    label: '室'
                },
                {
                    prop: 'communityName',
                    label: '所属项目'
                },
                {
                    prop: 'orgName',
                    label: '所属组织'
                }
            ],
            responseParams: {
                id: 'id',
                name: 'reflectHouse'
            },
            responseKey: {
                id: 'id',
                name: 'text'
            }
        },
        houseTypeOps: [],
    }
  },
  computed: {
    communitySubjoin () {
      return { communityId: this.form.communityId }
    },
    isEdit () {
      return !!this.$route.query.couponNumber
    },
    needPrice () {
      return this.form.couponType === 0
    },
    showCouponStatus () {
      let expired = this.form.couponStatus === 2
      return !expired
    },
    houseExtraParams () {
      return {
          communityId: this.form.communityId
      }
    }
  },
  mounted () {
      
    const { couponNumber } = this.$route.query
    if (couponNumber !== undefined) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = editCouponURL
      this.submitConfig.submitMethod = 'POST'
      this.$refs.formPanel.getData({
        'couponNumber': couponNumber
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = addCouponURL
    }
    this.getCommunityRommTypes()
  },
  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        if (data[key] !== null && data[key] !== undefined) {
          this.form[key] = data[key]
        }
      })
      this.form.time.startTime = data.startTime
      this.form.time.endTime = data.endTime
      this.form.expirationDateType = String(data.expirationDateType)
      this.form.houseNum = data.houseNum
      this.form.houseId = data.houseId
    },
    submitBefore (data) {
      data.startTime = this.form.time.startTime
      data.endTime = this.form.time.endTime
      let expirationDateType = parseInt(this.form.expirationDateType)
      if (expirationDateType === 1) {
        data.expirationDateType = expirationDateType
      } else {
        delete data.expirationHour
      }
      delete data.time
      if (this.form.couponType === 2) {
        // 一次全面不需要这两个字段
        delete data.price
        delete data.couponDuration
      } else if (this.form.couponType === 0) {
        // 金额
        delete data.couponDuration
      }
      if (!this.isEdit) {
          data.houseIds = data.houseIds.map(it => it.id)
      } else {
          delete data.houseIds
          delete data.houseId
          delete data.houseNum
      }
      return true
    },
    houseMultiSelect () {
       this.houseMultiShow = true
    },
    async getCommunityRommTypes () {
      const { houseTypeOps } = await getHouseTypeData()
      this.houseTypeOps = houseTypeOps(1)
    },
    onCommunityChange () {
        this.form.houseIds = []
    }
  }
}
</script>

<style lang="scss" scoped>
.ParkingCouponForm {
  .unit {
    margin-left: 8px;
    margin-right: 16px;
  }
  .radio-box {
    margin-right: 16px;
  }
}
</style>
